import React from 'react'
import Layout from "../components/Layout"
import Input from "../components/Input"

export default function RememberPassword() {
    return (
        <Layout>
            <div className="RememberPassword">
                <div className="RememberPassword-card">
                    <span className="RememberPassword-title">
                        ¿Has olvidado tu contraseña?
                    </span>
                    <span className="RememberPassword-text">
                        Si has olvidado tu contraseña, indica tu dirección de correo electrónico y te enviaremos un mensaje con instrucciones de cómo recuperarla.
                    </span>
                    <Input label="Email" type="email" />
                    <button className="RememberPassword-btn">Enviar</button>
                </div>
            </div>
        </Layout>
    )
}
